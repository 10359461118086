<template>
  <div v-if="data && data.delivery_date != null" class="mb-8">
    <div class="py-2 mb-4">
      <div>
        供應商出貨日期：<span class="blue--text text--darken-3">
          <!--data.delivery_date不等於null時才顯示-->
          {{ data.delivery_date ? formatDate(data.delivery_date) : "" }}</span
        >
      </div>
      <div>
        預計到貨時間：
        <!--data.delivery_date不等於null時才顯示-->
        <span v-if="data.delivery_date" class="blue--text text--darken-3">
          {{
            formatDateWithEstimatedDays(
              data.delivery_date,
              data.estimated_delivery_days
            )
          }}
          <!--data.delivery_time_start與data.delivery_time_end有值時顯示-->
          {{
            data.delivery_time_start || data.delivery_time_end
              ? data.delivery_time_end
                ? data.delivery_time_start + " ~ " + data.delivery_time_end
                : data.delivery_time_start
              : ""
          }}</span
        >
      </div>
    </div>
    <!--後端 collection.orderApi.read API尚未提供物流商資料 先註解起來 有資料在製作 by Leon 20240425-->
    <!-- <div>
      <div class="border-maxLightGray-b py-2">
        1.物流商：<span class="blue--text text--darken-3">7-11</span> /
        物流單號：<span class="blue--text text--darken-3">d3rerfsdff</span>
      </div>
      <div class="border-maxLightGray-b py-2">
        2.物流商：<span class="blue--text text--darken-3">郵局</span> /
        物流單號：<span class="blue--text text--darken-3">111uu4l1</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import ItemMixin from "@/components/form/custom/ItemMixin.js";

export default {
  mixins: [ItemMixin],
  data: () => ({
    data: null,
  }),
  watch: {
    propsValue: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.propsValue) return;
        console.log("OrderItems3.vue propsValue", this.propsValue);
        this.data = this.$eagleLodash.cloneDeep(this.propsValue);
      },
    },
  },
  computed: {},
  methods: {
    formatDate(dateString) {
      let date = new Date(dateString);
      return date.toLocaleDateString("zh-TW", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatDateWithEstimatedDays(delivery_date, estimated_delivery_days) {
      let date = new Date(delivery_date);
      date.setDate(date.getDate() + estimated_delivery_days);
      return date.toLocaleDateString("zh-TW", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
};
</script>
